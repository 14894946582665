$blue: #00a6eb;
$darkBlue: #015286;
$light-blue: #e7edf3;
$anthracite: #484848;
$medium-grey: #8a8a8a;
$light-grey: #c5c6c6;
$ultra-light-grey: #f4f4f4;
$white: #ffffff;
$black: #000000;
$red: #ad131d;
$green: #51bf2f;
$darkGreen: #3c8b24;
$orange: #ee7522;
$success: #51bf30;
$error: #ff525a;

$progressGrad: linear-gradient(
  90deg,
  rgba(0, 166, 235, 1) 0%,
  rgba(0, 234, 255, 1) 100%
);
$rejectedGrad: linear-gradient(
  90deg,
  rgba(255, 96, 103, 1) 0%,
  rgba(255, 198, 200, 1) 100%
);

$cancelGrad: linear-gradient(
  90deg,
  rgba(253, 81, 89, 1) 0%,
  rgba(170, 54, 59, 1) 100%
);

$greenGrad: linear-gradient(
  90deg,
  rgba(80, 188, 48, 1) 0%,
  rgba(63, 144, 37, 1) 100%
);

$lighten1: rgba(255, 255, 255, 0.25);
$lighten2: rgba(255, 255, 255, 0.5);
$lighten3: rgba(255, 255, 255, 0.8);

$darken1: rgba(0, 0, 0, 0.12);
$darken2: rgba(0, 0, 0, 0.24);
$darken3: rgba(0, 0, 0, 0.38);
$darken4: rgba(0, 0, 0, 0.5);
$darken5: rgba(0, 0, 0, 0.85);

:export {
  blue: $blue;
  darkBlue: $darkBlue;
  green: $green;
  darkGreen: $darkGreen;
  orange: $orange;
  lightBlue: $light-blue;
  anthracite: $anthracite;
  mediumGrey: $medium-grey;
  lightGrey: $light-grey;
  ultraLightGrey: $ultra-light-grey;
  white: $white;
  black: $black;
  error: $error;
  greenGrad: $greenGrad;
  progressGrad: $progressGrad;
  rejectedGrad: $rejectedGrad;
  cancelGrad: $cancelGrad;
}
