@import '../../styles/constants.module.scss';

.laser {
  width: 150%;
  margin-left: -25%;
  background-color: tomato;
  height: 2px;
  position: absolute;
  top: 20px;
  z-index: 2;
  box-shadow: 0 0 4px rgba(255, 0, 0, 0.5);
  animation: scanning 2s infinite;
}

.diode {
  animation: beam 0.01s infinite;
}

@keyframes beam {
  50% {
    opacity: 0.5;
  }
}

@keyframes scanning {
  50% {
    transform: translateY(25px);
  }
}
