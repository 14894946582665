@import '../../styles/constants.module.scss';
.gridContainer {
  background: rgb(0, 165, 234);
  background: linear-gradient(
    172deg,
    rgba(0, 165, 234, 1) 0%,
    rgba(0, 125, 180, 1) 100%
  );
}

.divContainer {
  display: flex;
  width: 85vw;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  justify-content: center;
}

.pinBoxContainer {
  width: 184px !important;
}

.pinBox {
  input {
    color: white;
    border: 1px $white;
  }
}

.laser {
  width: 150%;
  margin-left: -25%;
  background-color: tomato;
  height: 2px;
  position: absolute;
  top: 20px;
  z-index: 2;
  box-shadow: 0 0 4px rgba(255, 0, 0, 0.5);
  animation: scanning 2s infinite;
}

.diode {
  animation: beam 0.01s infinite;
}

@keyframes beam {
  50% {
    opacity: 0.5;
  }
}

@keyframes scanning {
  50% {
    transform: translateY(25px);
  }
}
