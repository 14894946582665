@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
body{margin:0;font-family:'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}



.Login_gridContainer__2wF_1{background:#00a5ea;background:linear-gradient(172deg, #00a5ea 0%, #007db4 100%)}.Login_divContainer__1yLxe{display:flex;width:85vw;flex-direction:column;align-items:center;margin-top:32px;justify-content:center}.Login_pinBoxContainer__3VqT9{width:184px !important}.Login_pinBox__ntv9D input{color:white;border:1px #fff}.Login_laser__2cAHz{width:150%;margin-left:-25%;background-color:tomato;height:2px;position:absolute;top:20px;z-index:2;box-shadow:0 0 4px rgba(255,0,0,0.5);-webkit-animation:Login_scanning__3kkj8 2s infinite;animation:Login_scanning__3kkj8 2s infinite}.Login_diode__9o05Q{-webkit-animation:Login_beam__2XabU 0.01s infinite;animation:Login_beam__2XabU 0.01s infinite}@-webkit-keyframes Login_beam__2XabU{50%{opacity:0.5}}@keyframes Login_beam__2XabU{50%{opacity:0.5}}@-webkit-keyframes Login_scanning__3kkj8{50%{transform:translateY(25px)}}@keyframes Login_scanning__3kkj8{50%{transform:translateY(25px)}}

.HeaderBar_HeaderBarOpen__1ZMGD{background:#35a4e8;background:linear-gradient(90deg, #35a4e8 0%, #2780b8 100%)}.HeaderBar_HeaderBarInProgress__1cptl{background:#50bc30;background:linear-gradient(90deg, #50bc30 0%, #3f9025 100%)}

.Home_HomeContainer__X1bHt{background:#f5f5f5;background:linear-gradient(180deg, #f5f5f5 0%, #c9e0ea 100%)}

.Scanning_laser__3XZuo{width:150%;margin-left:-25%;background-color:tomato;height:2px;position:absolute;top:20px;z-index:2;box-shadow:0 0 4px rgba(255,0,0,0.5);-webkit-animation:Scanning_scanning__1XicA 2s infinite;animation:Scanning_scanning__1XicA 2s infinite}.Scanning_diode__3F2BZ{-webkit-animation:Scanning_beam__Zsgo1 0.01s infinite;animation:Scanning_beam__Zsgo1 0.01s infinite}@-webkit-keyframes Scanning_beam__Zsgo1{50%{opacity:0.5}}@keyframes Scanning_beam__Zsgo1{50%{opacity:0.5}}@-webkit-keyframes Scanning_scanning__1XicA{50%{transform:translateY(25px)}}@keyframes Scanning_scanning__1XicA{50%{transform:translateY(25px)}}

.loader_loader-background__2Cp73{display:flex;background:rgba(255,255,255,0.7);position:fixed;top:0;height:100vh;width:100vw;align-items:center;justify-content:center;z-index:9000}.loader_first-circle__3Y2lV{position:absolute;-webkit-animation-name:loader_spin-right__3CEdd;animation-name:loader_spin-right__3CEdd;-webkit-animation-duration:2500ms;animation-duration:2500ms;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;-webkit-animation-timing-function:linear;animation-timing-function:linear}.loader_second-circle__3BZwT{position:absolute;-webkit-animation-name:loader_spin-left__OJ69O;animation-name:loader_spin-left__OJ69O;-webkit-animation-duration:2500ms;animation-duration:2500ms;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;-webkit-animation-timing-function:linear;animation-timing-function:linear}.loader_third-circle__aISDO{position:absolute;-webkit-animation-name:loader_spin-right__3CEdd;animation-name:loader_spin-right__3CEdd;-webkit-animation-duration:2500ms;animation-duration:2500ms;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;-webkit-animation-timing-function:linear;animation-timing-function:linear}@-webkit-keyframes loader_spin-right__3CEdd{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}@keyframes loader_spin-right__3CEdd{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}@-webkit-keyframes loader_spin-left__OJ69O{from{transform:rotate(360deg)}to{transform:rotate(0deg)}}@keyframes loader_spin-left__OJ69O{from{transform:rotate(360deg)}to{transform:rotate(0deg)}}

