.HeaderBarOpen {
  background: rgb(53, 164, 232);
  background: linear-gradient(
    90deg,
    rgba(53, 164, 232, 1) 0%,
    rgba(39, 128, 184, 1) 100%
  );
}

.HeaderBarInProgress {
  background: rgb(80, 188, 48);
  background: linear-gradient(
    90deg,
    rgba(80, 188, 48, 1) 0%,
    rgba(63, 144, 37, 1) 100%
  );
}
